body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Thin scrollbar track */
::-webkit-scrollbar {
  width: 8px;
}

/* Thin scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
}

/* Thin scrollbar track on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ddd transparent;
}

/* Thin scrollbar thumb on Firefox */
*::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
}
